/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

html {
	font-family: var(--font-primary);
	font-size: 10px;
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	overflow-x: hidden;
	font-size: var(--font-size--md);
	line-height: var(--line-height--2xl);
}

.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	overflow-x: hidden;

	> header,
	> main,
	> footer {
		flex: 0 0 100%;
		width: 100%;
	}

	> footer {
		margin-top: auto; // Push footer to bottom of page, regardless of how much content is on the page
	}
}
