/* ------------------------------------ *\
		elements.headings
\* ------------------------------------ */

/**
 * Elements – unclassed HTML elements,
 * redefine browsers' default styling
*/

h1 {
	@include heading--2xl;
}

h2 {
	@include heading--xl;
}

h3 {
	@include heading--lg;
}

h4 {
	@include heading--md;
}

h5 {
	@include heading--sm;
}

h6 {
	@include heading--xs;
}
