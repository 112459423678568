/* ------------------------------------ *\
		elements.paragraph
\* ------------------------------------ */

/**
 * Elements – unclassed HTML elements,
 * redefine browsers' default styling
*/

@import "ui/styles/2-tools/tools.media-query.scss";

p {
	margin-bottom: var(--spacing--sm);

	@include mq("md") {
		margin-bottom: var(--spacing--md);
	}

	&:last-child {
		margin-bottom: 0;
	}
}
