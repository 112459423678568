/* ------------------------------------ *\
		elements.embed
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

// Responsive iframes
.Embed {
	iframe {
	display: block;
	max-width: 100%;
	height: auto;
}
}

