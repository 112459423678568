/* ------------------------------------ *\
		elements.anchor
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/
@import "ui/styles/2-tools/tools.mixins.scss";

a {
	// Inherits font family from parent (not default in browsers)
	font-family: inherit;
	text-decoration: underline;

	@include HoverFocus {
		text-decoration: none;
	}
}
