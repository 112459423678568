/* ------------------------------------ *\
		settings.sizes
\* ------------------------------------ */

:root {
	--grid-gutter: 1rem;

	// Content Widths
	--content-width--base: 129rem;
	--content-width--xs: 65rem;
	--content-width--sm: 69rem;
	--content-width--md: 91rem;
	--content-width--lg: --content-width--base;
	--content-width--xl: 142rem;
	--content-width--2xl: 150rem;

	// Components
	--sizes--header-height-mobile: 8rem;
	--sizes--header-height-desktop: 15rem;
	--sizes--header-padding-bottom: var(--spacing--md);
	--sizes--sub-nav-item: 6.5rem;
	--sizes--nav-container-width: 42rem;
	--sizes--hamburger-width: 1.6rem;
	--sizes--hamburger-height: 1.25rem;
	--sizes-checkbox-height: 2rem;
	--sizes-input-height: 5.6rem;
	--sizes--hero-canvas-picture-offset: var(--spacing--2xl);

	// Icons
	--icon-size--base: 1rem;

	--icon-size--xs: calc(var(--icon-size--base) * 0.4);
	--icon-size--sm: calc(var(--icon-size--base) * 0.8);
	--icon-size--md: calc(var(--icon-size--base) * 2);
	--icon-size--lg: calc(var(--icon-size--base) * 3);
	--icon-size--xl: calc(var(--icon-size--base) * 4);
	--icon-size--2xl: calc(var(--icon-size--base) * 6);
}
