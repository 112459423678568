/* ------------------------------------ *\
		settings.typography
\* ------------------------------------ */

@font-face {
	font-family: "Raleway";
	src: url("/fonts/Raleway-VariableFont_wght.woff2") format("woff2-variations");
	font-weight: 125 950;
	font-stretch: 75% 125%;
	font-style: oblique 0deg 12deg;
}

@font-face {
	font-family: "Inter";
	src: url("/fonts/Inter-VariableFont_slnt,wght.woff2") format("woff2-variations");
	font-weight: 125 950;
	font-stretch: 75% 125%;
	font-style: oblique 0deg 12deg;
}

:root {
	// Font family
	--font-primary: "Raleway", sans-serif;
	--font-secondary: "Inter", sans-serif;

	// Font weights
	--font-weight--light: 300;
	--font-weight--normal: 400;
	--font-weight--medium: 500;
	--font-weight--semibold: 600;
	--font-weight--bold: 700;
	--font-weight--black: 800;
	--font-weight--extrablack: 900;

	// Font sizes
	--font-size--2xs: 0.75rem;
	--font-size--xs: 1rem;
	--font-size--sm: 1.4rem;
	--font-size--md: 1.6rem;
	--font-size--lg: clamp(2rem, 1.25vw + 1rem, 2.2rem);
	--font-size--xl: clamp(2.2rem, 1.5vw + 1rem, 2.6rem);
	--font-size--2xl: clamp(2.4rem, 2vw + 1rem, 3.2rem);
	--font-size--3xl: clamp(3.2rem, 3vw + 1rem, 4.8rem);
	--font-size--4xl: clamp(3.6rem, 4vw + 1rem, 6rem);
	--font-size--5xl: clamp(4rem, 5vw + 1rem, 8rem);

	// Components-specific font sizes
	--heading-font-size--2xs: clamp(1.8rem, 1.25vw + 1rem, 2rem);
	--heading-font-size--3xs: clamp(1.6rem, 1.25vw + 1rem, 1.8rem);

	--accordion-title-font-size: 1.8rem;

	--linkbar-font-size: 1.7rem;

	--formlabel-font-size--md: 1.5rem;
	--forminput-font-size--md: clamp(1.6rem, 1.25vw + 1rem, 1.8rem);

	--lead-font-size--md: clamp(1.6rem, 1.25vw + 1rem, 1.8rem);
	--lead-font-size--lg: clamp(1.7rem, 1.25vw + 1rem, 2rem);

	--quote-font-size--md: 2rem;
	--quote-font-size--lg: 2.4rem;

	--footercard-font-size-heading: 1.2rem;
	--footercard-font-size-body: 1.5rem;

	// Line heights
	--line-height--2xs: 1;
	--line-height--xs: 1.06;
	--line-height--sm: 1.12;
	--line-height--md: 1.17;
	--line-height--lg: 1.25;
	--line-height--xl: 1.3;
	--line-height--2xl: 1.5;
	--line-height--3xl: 2;
	--line-height--4xl: 3;
}
