.theme {
	// Miljøministeriet
	&__mim {
		--color-dark--1: #0e472f;
		--color-dark--2: #265944;
		--color-dark--3: #567e6d;
		--color-dark--4: #97b0a6;
		--color-action--1: #14643c;
		--color-action--2: #82ba79;
		--color-light--1: #d7e3d5;
		--color-light--2: #e6eee4;
		--color-light--3: #f4f7f3;

		// Components
		--hero-bg-light: var(--color-light--1);
		--hero-bg-dark: var(--color-dark--1);
		--hero-gradient-rgba-light: rgba(255, 255, 255, 0.7);
		--hero-gradient-rgba-dark: rgba(14, 71, 47, 0.7);
		--breadcrumb-bg-light: var(--hero-bg-light);
		--breadcrumb-bg-dark: var(--hero-bg-dark);
	}

	// Naturstyrelsen
	&__nst {
		--color-dark--1: #214827;
		--color-dark--2: #375a3d;
		--color-dark--3: #647f68;
		--color-dark--4: #a0b0a2;
		--color-action--1: #427032;
		--color-action--2: #9cbd73;
		--color-light--1: #dce5d2;
		--color-light--2: #e9eee3;
		--color-light--3: #f5f8f3;

		// Components
		--hero-bg-light: var(--color-light--1);
		--hero-bg-dark: var(--color-dark--1);
		--hero-gradient-rgba-light: rgba(255, 255, 255, 0.7);
		--hero-gradient-rgba-dark: rgba(33, 72, 39, 0.7);
		--breadcrumb-bg-light: var(--hero-bg-light);
		--breadcrumb-bg-dark: var(--hero-bg-dark);
	}

	// Kystdirektoratet
	&__kst {
		--color-dark--1: #08334c;
		--color-dark--2: #21475e;
		--color-dark--3: #527082;
		--color-dark--4: #95a7b2;
		--color-action--1: #1e6a96;
		--color-action--2: #63b1de;
		--color-light--1: #d9eaf5;
		--color-light--2: #e7f2f8;
		--color-light--3: #f4f9fc;

		// Components
		--hero-bg-light: var(--color-light--1);
		--hero-bg-dark: var(--color-dark--1);
		--hero-gradient-rgba-light: rgba(255, 255, 255, 0.7);
		--hero-gradient-rgba-dark: rgba(8, 51, 76, 0.7);
		--breadcrumb-bg-light: var(--hero-bg-light);
		--breadcrumb-bg-dark: var(--hero-bg-dark);
	}

	// Naturparkerne
	&__ntp {
		--color-dark--1: #0e4739;
		--color-dark--2: #26594d;
		--color-dark--3: #567e74;
		--color-dark--4: #97b0aa;
		--color-action--1: #2d7a61;
		--color-action--2: #64ba88;
		--color-light--1: #d8e8de;
		--color-light--2: #e8f2ec;
		--color-light--3: #f3f8f5;

		// Components
		--hero-bg-light: var(--color-light--1);
		--hero-bg-dark: var(--color-dark--1);
		--hero-gradient-rgba-light: rgba(255, 255, 255, 0.7);
		--hero-gradient-rgba-dark: rgba(14, 71, 57, 0.7);
		--breadcrumb-bg-light: var(--hero-bg-light);
		--breadcrumb-bg-dark: var(--hero-bg-dark);
	}

	// Klimatilpasning
	&__ktp {
		--color-dark--1: #33421d;
		--color-dark--2: #425722;
		--color-dark--3: #647d38;
		--color-dark--4: #98b260;
		--color-action--1: #4d7800;
		--color-action--2: #8fb53e;
		--color-light--1: #bed392;
		--color-light--2: #eaf0da;
		--color-light--3: #f2f7e6;

		// Components
		--hero-bg-light: var(--color-light--1);
		--hero-bg-dark: var(--color-dark--1);
		--hero-gradient-rgba-light: rgba(255, 255, 255, 0.7);
		--hero-gradient-rgba-dark: rgba(60, 93, 0, 0.8);
		--breadcrumb-bg-light: var(--hero-bg-light);
		--breadcrumb-bg-dark: var(--hero-bg-dark);
	}
}
