// TODO: SHOULD BE REMOVED AND REPLACED BY STYLING IN MODULE

/* ===========================================
	utilities.grid
	========================================= */

@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.grid-mixins.scss";

/**
 *  Utilities – utility- and helper classes
 *  with the ability to override anything
*/

// Grid based on BlazeUI's grid
// More info: http://blazeui.com/objects/grid/

/* Example use:

1. Basic grid with automatic cell-widths
(great for flexible grids with equal-width children)

<div class="u-grid">
	<div class="u-grid__cell">
		<!-- foo -->
	</div>
	<div class="u-grid__cell">
		<!-- foo -->
	</div>
</div>


2. Traditional, fixed-width grid with responsive values

 - .u-container: wrapper with max-width for content (.u-grid doesn't depend on this)
 - .u-grid--wrap: allows cells to wrap, when their total width exceeds 100
 - .u-grid__cell--width-X: sets width to X%
 - .u-grid__cell--width-X@Y: sets width to X% from min-width Y breakpoint

<section class="u-container">
	<div class="u-grid u-grid--wrap">
		<div class="u-grid__cell u-grid__cell--width-100 u-grid__cell--width-66@md">
			<!-- foo -->
		</div>
		<div class="u-grid__cell u-grid__cell--width-100 u-grid__cell--width-33@md">
			<!-- foo -->
		</div>
	</div>
</section>

*/

.u-grid {
	@include grid;

	margin-left: calc(var(--grid-gutter) * -1);
	margin-right: calc(var(--grid-gutter) * -1);

	&--right {
		@include grid--right;
	}

	&--left {
		@include grid--left;
	}

	&--wrap {
		@include grid--wrap;
	}

	&--top {
		@include grid--top;
	}

	&--center {
		@include grid--center;
	}

	&--horizontal-center {
		@include grid--horizontal-center;
	}

	&--bottom {
		@include grid--bottom;
	}

	&--full {
		@include grid--full;

		> .u-grid__cell {
			@include grid__cell--full;
		}
	}

	&--no-gutter {
		margin-left: 0;
		margin-right: 0;

		> .u-grid__cell {
			@include grid__cell--no-gutter;
		}
	}

	&--row-gap {
		> .u-grid__cell {
			@include grid__cell--row-gap;
		}
	}

	&--space-between {
		justify-content: space-between;
		> .u-grid__cell {
			flex-basis: auto;
			flex-grow: 0;
		}
	}

	&--stretch {
		align-items: stretch;
	}

	&--container {
		margin-left: calc(-1 * var(--grid-gutter));
		margin-right: calc(-1 * var(--grid-gutter));
	}

	&__cell {
		@include grid__cell;

		@include grid__cells;

		&--top {
			@include grid__cell--top;
		}

		&--center {
			@include grid__cell--center;
		}

		&--bottom {
			@include grid__cell--bottom;
		}

		&--no-gutter {
			@include grid__cell--no-gutter;
		}

		&--width-fixed {
			@include grid__cell--width-fixed;
		}

		&--hidden {
			@include grid__cell--hidden;
		}

		&--visible {
			@include grid__cell--visible;
		}
	}
}

// Responsive cells widths
.u-grid__cell {
	@include mq("xs") {
		@include grid__responsive-cells(xs);
	}

	@include mq("sm") {
		@include grid__responsive-cells(sm);
	}

	@include mq("md") {
		@include grid__responsive-cells(md);
	}

	@include mq("lg") {
		@include grid__responsive-cells(lg);
	}

	@include mq("xl") {
		@include grid__responsive-cells(xl);
	}

	&--flex {
		display: flex;
	}
}
