/* ------------------------------------ *\
		generic.tables
\* ------------------------------------ */

@import "ui/styles/2-tools/tools.media-query.scss";

/**
 * Generic – reset and/or normalize styles,
 * box-sizing definition, etc.
*/

table {
	position: relative;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
	font-family: var(--font-secondary);
	font-size: var(--font-size--md);

	tr {
		background-color: var(--color-light--3);

		&:nth-of-type(even) {
			background-color: transparent;
		}
	}

	th,
	td {
		border: 0;
		padding: var(--spacing--3xs) var(--spacing--xs);
		min-height: var(--spacing--lg);
		font-size: var(--font-size--md);
		font-weight: var(--font-weight--normal);

		@include mq("md") {
			padding-left: var(--spacing--md);
			padding-right: var(--spacing--md);
		}
	}

	th,
	tfoot td {
		text-align: left;
		background-color: var(--color-light--3);
	}

	// Handle cases where table markup uses thead and tbody
	thead + tbody {
		tr {
			background-color: transparent;

			&:nth-of-type(even) {
				background-color: var(--color-light--3);
			}
		}
	}
}
