/* ------------------------------------ *\
		settings.spacing
\* ------------------------------------ */

:root {
	--spacing--base: 2rem;

	--spacing--5xs: calc(var(--spacing--base) / 4);
	--spacing--4xs: calc(var(--spacing--base) / 3);
	--spacing--3xs: calc(var(--spacing--base) / 2.5);
	--spacing--2xs: calc(var(--spacing--base) / 1.6667);
	--spacing--xs: calc(var(--spacing--base) / 1.25);
	--spacing--sm: calc(var(--spacing--base) * 1.2);
	--spacing--md: calc(var(--spacing--base) * 1.6);
	--spacing--lg: calc(var(--spacing--base) * 2);
	--spacing--xl: calc(var(--spacing--base) * 2.4);
	--spacing--2xl: calc(var(--spacing--base) * 3.2);
	--spacing--3xl: calc(var(--spacing--base) * 4);
	--spacing--4xl: calc(var(--spacing--base) * 5);
	--spacing--5xl: calc(var(--spacing--base) * 6);
	--spacing--6xl: calc(var(--spacing--base) * 7);

	--spacing--main-top: var(--spacing--xl);
}
