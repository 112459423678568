/* ------------------------------------ *\
		elements.blockquote
\* ------------------------------------ */

/**
 * Elements – unclassed HTML elements,
 * redefine browsers' default styling
*/

@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.media-query.scss";

blockquote {
	@include quote;

	margin-top: var(--spacing--md);
	margin-bottom: var(--spacing--md);

	@include mq("md") {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--xl);
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}
