/* ------------------------------------ *\
		settings.colors
\* ------------------------------------ */

:root {
	// Global colors
	--color-black: #1f1f1f;
	--color-darkGrey: #6e6e6e;
	--color-mediumGrey: #bababa;
	--color-light--1Grey: #dadada;
	--color-white: #ffffff;
	--color-focus-outline: #006ff9;

	// Variations of gray named with the alpha percentage
	--color-gradient--5: hotpink;
	--color-gradient--8: hotpink;
	--color-gradient--10: hotpink;
	--color-gradient--20: hotpink;
	--color-gradient--30: hotpink;
	--color-gradient--40: hotpink;
	--color-gradient--50: hotpink;
	--color-gradient--60: hotpink;
	--color-gradient--70: hotpink;
	--color-gradient--80: hotpink;
	--color-gradient--90: hotpink;
	--color-gradient--100: hotpink;

	// Theme colors
	--color-dark--1: #0e4743;
	--color-dark--2: #265956;
	--color-dark--3: #567e78;
	--color-dark--4: #97b0ac;
	--color-action--1: #287876;
	--color-action--2: #78b5b1;
	--color-light--1: #dae5df;
	--color-light--2: #eaf0ed;
	--color-light--3: #f0f5f3;

	// Component colors
	--hero-bg-light: var(--color-light--1);
	--hero-bg-dark: var(--color-dark--1);
	--hero-gradient-rgba-light: rgba(255, 255, 255, 0.7);
	--hero-gradient-rgba-dark: rgba(14, 71, 67, 0.7);
	--breadcrumb-bg-light: var(--hero-bg-light);
	--breadcrumb-bg-dark: var(--hero-bg-dark);

	// Utilities colors
	// TODO: LARS! Slet disse
	--color-error: #c80000;
	--color-dark: #2c2c2c;

	// Border colors
	--color-border--gray: var(--color-gradient--20);
	--color-border--light: var(--color-light--1);

	// Shadows
	// Note: In newer versions of Sass, string interpolation is necessary to use the rgba() function directly in CSS variables
	// See also: https://stackoverflow.com/a/50204145
	--shadow-color--milder: #{rgba(#2c2c2c, 0.1)};
	--shadow-color--mild: #{rgba(#2c2c2c, 0.2)};
	--shadow-color--base: #{rgba(#2c2c2c, 0.5)};
}
