@use "sass:math";
/* ------------------------------------ *\
		tools.mixins
\* ------------------------------------ */

@mixin break-word() {
	word-break: break-all;
	word-break: break-word;
	hyphens: auto;
}

// hide placeholder text for all text inputs
@mixin hide-placeholder() {
	&::-webkit-input-placeholder {
		color: transparent; /* Chrome <=56, Safari < 10 */
	}

	&:-moz-placeholder {
		color: transparent; /* FF 4-18 */
	}

	&::-moz-placeholder {
		color: transparent; /* FF 19-51 */
	}

	&:-ms-input-placeholder {
		text-indent: -1000px; /* IE 10+ */
	}

	&::placeholder {
		color: transparent; /* Modern Browsers */
	}
}

@mixin HoverFocus {
	&:hover,
	&:active,
	&:focus,
	&:focus-visible,
	&:focus-within {
		@content;
	}
}
